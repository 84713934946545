


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  name: 'seat-plan',
})
export default class SeatPlan extends Vue {

  public mounted(): void {
    this._loadSeatPlanViewer();
  }

  private _loadSeatPlanViewer(): void {
    const scriptId = 'biletumSeatPlanViewer';
    const script = document.getElementById(scriptId) as HTMLScriptElement;
    if (!script || script.type !== 'text/postloaded') {
      return;
    }
    script.parentNode.removeChild(script);
    script.type = 'text/javascript';
    script.src = script.getAttribute('data-src');
    document.getElementsByTagName('head')[0].appendChild(script);
  }

}

